//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { _gettree, _getcode } from '@/api/organizational.js'
import {
  _getBindInfo,
  _getConfigPeople,
  _spapply,
  _apply,
  _getselectUserBindDeptList,
  _getshowBZPerson,
  _getCorrentDepts,
  _getgetCheckers,
  _getgetConfirms,
} from '@/api/disabilities.js'
export default {
  data() {
    return {
      btnDisab: false,
      videoFlag: false,
      //是否显示进度条
      videoUploadPercent: '',
      //进度条的进度，
      isShowUploadVideo: false,
      //显示上传按钮
      videoForm: [],
      btnDats: false,
      AudioForm: [],
      currentTime: '',
      duration: '',
      dialogVisible: false,
      uesrlistsnew: [],
      imgsList: [], // 文件上传列表
      dialogImageUrl: '', //查看大图
      uesrlistArray: [],
      uesrlist: [],
      options: [],
      typesList: [],
      imageUrlsec: [],
      curcityist: [],
      fileVList: [],
      AUDIO: '',
      faultImgList: [], //故障照片上传成功的ids数组
      forms: {
        reportId: '', //部门ID
        applyType: '', //类型
        address: '', //地址
        price: '', //金额
        reportDname: '',
        // bindId: "", //合同id
        checkIds: [], //验收人
        confirmIds: [], //审批人
        contact: '', //联系人
        phone: '', //手机
        reportInfo: '', //保障说明
        reportLevel: '', //保障等级
        reportTypes: '', //故障类型
        ossIds: [], //提交时候的数组
      },
      rules: {
        applyType: [
          { required: true, message: '请选择故障所属单位', trigger: 'change' },
        ],
        reportId: [
          { required: true, message: '请选择故障部门', trigger: 'change' },
        ],
        address: [{ required: true, message: '地址不能为空', trigger: 'blur' }],
        price: [
          { required: true, message: '维修金额不能为空', trigger: 'blur' },
        ],
        checkIds: [
          { required: true, message: '验收人不能为空', trigger: 'blur' },
        ],
        reportDname: [
          { required: true, message: '请输入故障部门', trigger: 'blur' },
        ],
        contact: [
          { required: true, message: '联系人不能为空', trigger: 'blur' },
        ],
        phone: [
          { required: true, message: '手机号不能为空', trigger: 'blur' },
          {
            required: true,
            pattern: /^1[123456789]\d{9}$/,
            message: '请输入正确的手机号',
          },
        ],
        reportInfo: [
          { required: true, message: '报障说明不能为空', trigger: 'blur' },
        ],
        reportLevel: [
          { required: true, message: '报障等级不能为空', trigger: 'change' },
        ],

        reportTypes: [
          { required: true, message: '故障类型能为空', trigger: 'change' },
        ],
      },
    }
  },
  created() {
    this.forms.checkIds = []
    this.forms.confirmIds = []
    this.getcode()
    // 默认回显数据
    // _getshowBZPerson({}).then((res) => {
    //   this.forms.phone = res.data.contects[0].phone;
    //   this.forms.contact = res.data.contects[0].name;
    // });
  },
  methods: {
    //视频上传前回调
    beforeUploadVideo(file) {
      var fileSize = file.size / 1024 / 1024 < 50
      if (
        [
          'audio/mpeg',
          'video/mp4',
          'video/ogg',
          'video/flv',
          'video/avi',
          'video/wmv',
          'video/rmvb',
          'video/mov',
        ].indexOf(file.type) == -1
      ) {
        this.$message('请上传正确的视频格式')
        return false
      }
      if (!fileSize) {
        this.$message('视频大小不能超过50MB')
        return false
      }
      this.isShowUploadVideo = false
    },
    //视频进度条
    uploadVideoProcess(event, file, fileList) {
      this.videoFlag = true
      this.videoUploadPercent = file.percentage.toFixed(0) * 1
    },
    //视频上传成功回调
    handleVideoSuccess(res, file) {
      this.isShowUploadVideo = true
      this.videoFlag = false
      this.videoUploadPercent = 0
      //后台上传地址
      if (res.code == '1') {
        this.videoForm.push({
          showVideoPath:
            `/ananops/api/v1/pub/file/download?ossId=${res.data.ossId}` +
            '&type=pc',
          ossId: res.data.ossId,
        })
      }
    },
    // 移除视频
    delVideo(index) {
      this.videoForm.splice(index, 1)
    },
    // 音频的成功事件
    handleAudioSuccess(res) {
      if (res.code == '1') {
        this.AudioForm.push({
          audio:
            `/ananops/api/v1/pub/file/download?ossId=${res.data.ossId}` +
            '&type=pc',
          ossId: res.data.ossId,
        })
      }
    },
    // 获取总时长
    async getDuration(e) {
      const firsthandAudio = e.target
      while (firsthandAudio.duration === Infinity) {
        await new Promise((r) => setTimeout(r, 200))
        firsthandAudio.currentTime = 10000000 * Math.random()
      }
      this.duration = firsthandAudio.duration
    },
    // 移除音频
    delAudio(index) {
      this.AudioForm.splice(index, 1)
    },

    // 获取角色下拉数据
    getcode() {
      let from = {
        para: 'gzdj',
      }
      _getcode(from).then((res) => {
        this.options = res.data
      })
      _getcode({ para: 'gzlx' }).then((res) => {
        this.typesList = res.data
      })
      _getcode({ para: 'bzdwlx' }).then((res) => {
        this.curcityist = res.data
      })
    },
    // 故障部门的change事件
    change(val) {
      if (val == '1') {
        this.forms.reportId = ''
        this.forms.reportDname = ''
        this.forms.checkIds = []
        this.forms.confirmIds = []
        this.forms.phone = ''
        this.forms.contact = ''
        this.btnDats = false
        _getCorrentDepts({}).then((res) => {
          if (res.code == '1') {
            this.uesrlistsnew = res.data
          }
        })
      } else if (val == '2') {
        this.btnDats = true
        this.forms.reportId = ''
        this.forms.checkIds = []
        this.forms.confirmIds = []
        this.forms.phone = ''
        this.forms.contact = ''
        _getgetConfirms({ did: '' }).then((res) => {
          if (res.code == '1') {
            // 审批人
            this.uesrlist = res.data
          }
        })
        _getgetCheckers({ did: '' }).then((res) => {
          // 验收人
          if (res.code == '1') {
            this.uesrlistArray = res.data
          }
        })
        let that = this
        _getshowBZPerson({ did: '' }).then((res) => {
          console.log(res)
          // 验收人的数据
          // this.uesrlist = res.data.confirms
          res.data.checks.forEach((item) => {
            that.forms.checkIds.push(item.id)
          })
          // 审批人
          // this.uesrlistArray = res.data.checks
          res.data.confirms.forEach((item) => {
            that.forms.confirmIds.push(item.id)
          })
          this.forms.phone = res.data.contects[0].phone
          this.forms.contact = res.data.contects[0].name
        })
      }
    },
    handleSelectGroup(ids) {
      ;(this.forms.checkIds = []), //验收人
        (this.forms.confirmIds = []), //审批人
        // 验收人
        _getgetCheckers({ did: ids }).then((res) => {
          if (res.code == '1') {
            this.uesrlistArray = res.data
          }
        }),
        // 审批人
        _getgetConfirms({ did: ids }).then((res) => {
          if (res.code == '1') {
            this.uesrlist = res.data
          }
        })
      this.uesrlistsnew.filter((item) => {
        if (item.spDid == ids) {
          this.forms.bindId = item.id
        }
        console.log(ids)
        let that = this
        _getshowBZPerson({ did: ids }).then((res) => {
          console.log(res)
          // 验收人的数据
          // this.uesrlist = res.data.confirms
          that.forms.checkIds = []
          res.data.checks.forEach((item) => {
            that.forms.checkIds.push(item.id)
          })
          // 审批人
          // this.uesrlistArray = res.data.checks
          that.forms.confirmIds = []
          res.data.confirms.forEach((item) => {
            that.forms.confirmIds.push(item.id)
          })
          this.forms.phone = res.data.contects[0].phone
          this.forms.contact = res.data.contects[0].name
        })
      })
      // 所有的部门数据
      this.uesrlistsnew.filter((item) => {
        if (ids === item.id) {
          this.forms.address = item.address
        }
      })
    },
    // 图片上传成功拿到orid
    successFile(response, file, fileList) {
      this.faultImgList.push(response.data.ossId) //故障图片上传返回的ossid数组
    },

    // 点击大图显示
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    // 移除图片
    removeFile(file, fileList) {
      this.faultImgList.splice(file.response.data.ossId, 1)
    },
    // 提交
    save() {
      let video = this.videoForm.map((item) => {
        return item.ossId
      })
      let audio = this.AudioForm.map((item) => {
        return item.ossId
      })
      this.forms.ossIds = [...this.faultImgList, ...video, ...audio]
      this.$refs['form'].validate((valid) => {
        if (valid) {
          if (this.forms.ossIds.length != 0) {
            this.btnDisab = true
            _spapply(this.forms)
              .then((res) => {
                if (res.code == '1') {
                  this.$message(res.data.message)
                  this.btnDisab = false
                  this.$router.push({
                    path: '/main/report/order',
                    query: { selected: res.data.code },
                  })
                } else {
                  this.btnDisab = false
                }
              })
              .catch(() => {
                this.btnDisab = false
              })
          } else {
            this.$message('请至少上传任意一个故障材料')
          }
        }
      })
    },
  },
}
